module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__encoding@0.1.13__4tl345ytpeckkrtevtxu2eosxq/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.10.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._m5sqdjwxvim5he7f3xvmmu4vxu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
